// Colors
$color-mozorange: rgba(255, 86, 33, 1);
$color-spacecadet-dark: rgba(0, 51, 77, 1);
$color-spacecadet: rgba(0, 61, 89, 1);
$color-spacecadet-75: rgba(0, 61, 89, 0.75);
$color-spacecadet-70: rgba(0, 61, 89, 0.7);
$color-spacecadet-50: rgba(0, 61, 89, 0.5);
$color-spacecadet-35: rgba(0, 61, 89, 0.35);
$color-oracle: rgba(40, 93, 93, 1);
$color-fadedjade: rgba(68, 133, 125, 1);
$color-freadazoid: rgba(0, 216, 239, 1);
$color-neoncarrot: rgba(251, 147, 52, 1);
$color-deepcerulean: rgba(2, 121, 176, 1);
$color-deepcerulean-20: rgba(2, 121, 176, 0.2);

$color-success: rgba(66, 175, 134, 1);
$color-info: rgba(0, 170, 249, 1);
$color-warning: rgba(252, 175, 42, 1);
$color-danger: rgba(255, 71, 83, 1);

$color-darkgray: rgba(84, 97, 104, 1);
$color-darkgray-80: rgba(84, 97, 104, 0.8);
$color-darkgray-60: rgba(84, 97, 104, 0.6);
$color-darkgray-35: rgba(84, 97, 104, 0.35);
$color-darkgray-15: rgba(84, 97, 104, 0.15);
$color-darkgray-5: rgba(84, 97, 104, 0.05);

$color-midgray: rgba(164, 175, 181, 1);
$color-midgray-70: rgba(164, 175, 181, 0.7);
$color-midgray-60: rgba(164, 175, 181, 0.6);
$color-midgray-50: rgba(164, 175, 181, 0.5);
$color-midgray-35: rgba(164, 175, 181, 0.35);
$color-midgray-20: rgba(164, 175, 181, 0.2);
$color-lightgray: rgba(225, 233, 237, 1);
$color-lightgray-71: rgba(225, 233, 237, 0.71);
$color-lightgray-50: rgba(225, 233, 237, 0.5);
$color-lightgray-25: rgba(225, 233, 237, 0.25);
$color-lightgray-15: rgba(225, 233, 237, 0.15);
$color-lightgrayer: rgba(246, 248, 249, 1);
$color-white: rgba(255, 255, 255, 1);
$color-black: rgba(0, 0, 0, 1);
$color-black-7: rgba(0, 0, 0, 0.07);
$color-black-12: rgba(0, 0, 0, 0.12);
$color-black-87: rgba(0, 0, 0, 0.87);
$color-aliceblue-light: rgba(251, 252, 253, 1);
$color-skyblue: rgba(220, 246, 248, 1);
$color-skyblue-80: rgba(220, 246, 248, 0.8);
$color-skyblue-70: rgba(220, 246, 248, 0.7);
$color-creme: rgba(255, 253, 208, 1);
$color-purple-5: rgba(210, 0, 80, 0.5);
$color-butter: rgba(254, 225, 43, 1);
$color-bumblebee: rgba(255, 210, 62, 1);
$color-sapphire: rgba(50, 197, 255, 1);
$color-ruby: rgba(224, 32, 32, 1);

// Sizes
$size-xs: 0.75em;
$size-sm: 0.875em;
$size-1x: 1em;
$size-lg: 1.33em;
$size-2x: 2em;
$size-3x: 3em;
$size-4x: 4em;
$size-5x: 5em;
$size-6x: 6em;
$size-7x: 7em;
$size-8x: 8em;
$size-9x: 9em;
$size-10x: 10em;

//Font Family
$default-font: "Montserrat", sans-serif;

:export {
  color: {
    mozorange: $color-mozorange;
    spacecadet-dark: $color-spacecadet-dark;
    spacecadet: $color-spacecadet;
    spacecadet-75: $color-spacecadet-75;
    spacecadet-70: $color-spacecadet-70;
    spacecadet-50: $color-spacecadet-50;
    spacecadet-35: $color-spacecadet-35;
    oracle: $color-oracle;
    fadedjade: $color-fadedjade;
    freadazoid: $color-freadazoid;
    neoncarrot: $color-neoncarrot;
    deepcerulean: $color-deepcerulean;
    deepcerulean-20: $color-deepcerulean-20;
    success: $color-success;
    info: $color-info;
    warning: $color-warning;
    danger: $color-danger;
    darkgray: $color-darkgray;
    darkgray-80: $color-darkgray-80;
    darkgray-60: $color-darkgray-60;
    darkgray-15: $color-darkgray-15;
    darkgray-5: $color-darkgray-5;
    midgray: $color-midgray;
    midgray-70: $color-midgray-70;
    midgray-60: $color-midgray-60;
    midgray-50: $color-midgray-50;
    midgray-35: $color-midgray-35;
    midgray-20: $color-midgray-20;
    lightgray: $color-lightgray;
    lightgray-71: $color-lightgray-71;
    lightgray-50: $color-lightgray-50;
    lightgray-25: $color-lightgray-25;
    lightgray-15: $color-lightgray-15;
    lightgrayer: $color-lightgrayer;
    white: $color-white;
    aliceblue-light: $color-aliceblue-light;
    black: $color-black;
    black-7: $color-black-7;
    black-12: $color-black-12;
    black-87: $color-black-87;
    skyblue: $color-skyblue;
    skyblue-80: $color-skyblue-80;
    skyblue-70: $color-skyblue-70;
    creme: $color-creme;
    purple-5: $color-purple-5;
    butter: $color-butter;
    bumblebee: $color-bumblebee;
    sapphire: $color-sapphire;
    ruby: $color-ruby;
  }
  size: {
    xs: $size-xs;
    sm: $size-sm;
    x1: $size-1x;
    lg: $size-lg;
    x2: $size-2x;
    x3: $size-3x;
    x4: $size-4x;
    x5: $size-5x;
    x6: $size-6x;
    x7: $size-7x;
    x8: $size-8x;
    x9: $size-9x;
    x10: $size-10x;
  }
  fontfamily: {
    default: $default-font;
  }
}
