/** Please refer to: https://sass-lang.com/guide **/

/* Variables */
@import "variables";

/* Core */
.x-lockedgrid {
  .x-lockedgridregion.x-lock-start {
    border-color: $color-midgray-50 !important;
    border-right-width: 1px !important;
    min-width: 43px;
  }

  .x-lockedgridregion.x-lock-end {
    border-color: $color-midgray-50 !important;
  }

  .x-gridrow {
    border-right: 0;
    border-left: 0;

    .x-cells-el {
      height: 100%;
    }

    .x-gridcell {
      height: 50px;
    }
  }

  .x-mask {
    opacity: 0.1;
    background-color: #111;
  }
}

.swipeup {
  transform: translateY(-100vh) 1s ease-in-out;
}

.fade-out {
  animation: fade 1.4s;
  -webkit-animation: fade 1.4s;
  -moz-animation: fade 1.4s;
}

@keyframes fade {
  from { opacity: 1 }
  to { opacity: 0 }
}
@-moz-keyframes fade {
  from { opacity: 1 }
  to { opacity: 0 }
}
@-webkit-keyframes fade {
  from { opacity: 1 }
  to { opacity: 0 }
}

#modal {
  position: fixed; 
  top: 0;
  left: 45%;
  z-index: 1000000;
}


.grid-row-summary {
  background-color: $color-darkgray !important;

  .x-gridcell-body-el {
    color: $color-white;
    font-weight: bold;

    a {
      color: $color-white;
      pointer-events: none;
    }

    a:hover {
      text-decoration: none;
    }
  }
}

.grid-row-parent-account {
  background-color: $color-midgray !important;

  .x-gridcell-body-el {
    color: $color-spacecadet;
    font-weight: bold;

    a {
      color: $color-spacecadet;
    }

    a:hover {
      color: $color-spacecadet;
    }

    a:visited {
      color: $color-spacecadet;
    }
  }
}

.x-panelresizer.x-splitter {
  background-color: transparent !important;
}

.grouped-no-headers .sencha-grid-grouped {
  --header-background-color: #{$color-midgray-35};

  .x-rowheader,
  .x-tool-itemheader .x-icon-el {
    color: #{$color-spacecadet};
  }

  .x-itemheader {
    border-top: 2px solid #{$color-spacecadet-35};
  }

  .x-itemheader > .x-body-el, .x-itemheader > .x-tool-dock {
    min-width: 100%;
    display: none;
    visibility:hidden;
  }
}

.x-gridcolumn.not-a-template-field .x-header-el {
  background-color: $color-purple-5 !important;

  .x-trigger-el:hover {
    background-color: $color-purple-5;
  }

  .x-trigger-el {
    background-color: $color-purple-5;
  }
}

.x-gridcolumn .x-header-el {
  display: flex;
  align-items: flex-end;
}

.pagingtoolbar-button {
  background-color: $color-mozorange;
  color: $color-white;
  border-radius: 4px;
}
.paging-toolbar-page-input .x-input-wrap-el,
.paging-toolbar-page-size-select {
  background-color: $color-lightgray-15;
  border-radius: 4px 4px 0 0;

  &:hover {
    background-color: $color-lightgray-50;
  }
}
.paging-toolbar-page-input .x-input-wrap-el {
  padding: 0 4px;
}

.paging-toolbar-page-size-select .x-input-wrap-el {
  padding: 4px;
}

.x-pagingtoolbar .x-pagingtoolbar-first:before {
  font-family: "Font Awesome 5 Free";
  content: "\f100";
}

.x-pagingtoolbar .x-pagingtoolbar-last:before {
  font-family: "Font Awesome 5 Free";
  content: "\f101";
}

.rotate {
  .x-header-el {
    display: block;
    padding: 0px 10px 0px 10px;
    height: 100%;

    .x-title-wrap-el {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
      display: table;
      table-layout: fixed;

      .x-title-el {
        vertical-align: bottom;
        display: table-cell;

        .x-text-el {
          width: 200px;
          height: 200px;
          display: block;
          margin-left: auto;
          margin-right: auto;
          text-align: left;
          -webkit-transform: rotate(-90deg);
          -moz-transform: rotate(-90deg);
          -o-transform: rotate(-90deg);
          -ms-transform: rotate(-90deg);
        }
      }
    }
  }
}

.mwc-agent {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-agent.svg");
  background-size: 200px 182px;
}
.mwc-department {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-department.svg");
  background-size: 200px 182px;
}
.mwc-cookie {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-cookie.svg");
  background-size: 200px 182px;
}
.mwc-folderadd {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-folderadd.svg");
  background-size: 200px 182px;
}
.mwc-folder {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-folder-open-duotone.svg");
  background-size: 200px 182px;
}
.mwc-mwc-agentcheck {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-agentcheck.svg");
  background-size: 200px 182px;
}

.mwc-collectionassign {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-collectionassign.svg");
  background-size: 200px 182px;
}

.mwc-sequenceassign {
  display: inline-block;
  width: 200px;
  height: 182px;
  background: url("../icons/mwc-sequence.svg");
  background-size: 200px 182px;
}

.grid-empty-text {
  font-size: 1.5rem;
  color: $color-midgray;
  font-weight: 400;
}

.grid-empty-subtext {
  font-size: 1rem;
  color: $color-midgray;
  font-weight: 400;
}
